import filter from "lodash/filter";
import { withRouter } from "react-router-dom";

import { Footer } from "@anyfin/ui";
import information from "@anyfin/company-info";
import { useCountry } from "@anyfin/number-formatter/components";
import { TFunction, useTranslation } from "react-i18next";
import CountrySelect from "../components/CountrySelect";
import { downloadApp } from "../utils/client";

import iconInstagram from "../assets/icon_instagram.svg";
import iconFacebook from "../assets/icon_facebook.svg";
import iconYoutube from "../assets/icon_youtube.svg";
import { COUNTRY_CODES } from "../utils/countries";

const ANYFIN_URL = import.meta.env.VITE_ANYFIN_WEB_URL;

const menuLinks = [
  {
    link: ANYFIN_URL + "/sv_SE",
    text: "Hem",
    primary: true
  },
  {
    link: ANYFIN_URL + "/sv_SE/om",
    text: "Varför Anyfin?",
    primary: true
  },
  {
    link: ANYFIN_URL + "/sv_SE/faq",
    text: "Vanliga frågor",
    primary: true
  },
  {
    link: "/",
    text: "Mina Sidor",
    primary: true
  },
  {
    link: "https://career.anyfin.com/",
    text: "Karriär",
    primary: false
  },
  {
    link: ANYFIN_URL + "/sv_SE/press",
    text: "Press",
    primary: false
  },
  {
    link: ANYFIN_URL + "/sv_SE/raknare",
    text: "Räknare",
    primary: false
  }
];

const footerLinks = {
  SE: {
    menuLinksPrimary: filter(menuLinks, x => x.primary),
    menuLinksSecondary: filter(menuLinks, x => !x.primary),
    compliance: [
      {
        text: "SEKKI",
        link: information.SE.SECCI
      },
      {
        text: "Integritetspolicy",
        link: ANYFIN_URL + "/sv_SE/integritetspolicy"
      },
      {
        text: "Kakor",
        link: ANYFIN_URL + "/sv_SE/kakor"
      },
      {
        text: "Klagomål",
        link: ANYFIN_URL + "/sv_SE/klagomal"
      }
    ],
    social: [
      {
        text: "Instagram",
        src: iconInstagram,
        link: information.SE.social.instagram
      },
      {
        text: "Facebook",
        src: iconFacebook,
        link: information.SE.social.facebook
      },
      {
        src: iconYoutube,
        link: information.SE.social.youtube
      }
    ]
  },
  FI: {
    menuLinksPrimary: [
      {
        link: ANYFIN_URL + "/fi",
        text: "Etusivu"
      },
      {
        link: "https://career.anyfin.com/",
        text: "Uraa"
      },
      {
        link: "/",
        text: "Omat sivut"
      }
    ],
    menuLinksSecondary: [
      {
        link: ANYFIN_URL + "/fi_FI/press",
        text: "Tiedotteet",
        primary: false
      },
      {
        link: ANYFIN_URL + "/fi_FI/contact",
        text: "Ota yhteyttä",
        primary: false
      }
    ],
    compliance: [
      {
        link: information.FI.SECCI,
        text: "SECCI"
      },
      {
        link: ANYFIN_URL + "/fi_FI/privacy-policy",
        text: "Tietosuojaseloste"
      },
      {
        link: ANYFIN_URL + "/fi_FI/cookies",
        text: "Evästeet"
      },
      {
        link: ANYFIN_URL + "/fi_FI/complaints",
        text: "Reklamaatiot"
      }
    ],
    social: [
      {
        text: "Instagram",
        src: iconInstagram,
        link: information.FI.social.instagram
      },
      {
        text: "Facebook",
        src: iconFacebook,
        link: information.SE.social.facebook
      },
      {
        text: "YouTube",
        src: iconYoutube,
        link: information.SE.social.youtube
      }
    ]
  },
  DE: {
    menuLinksPrimary: [
      {
        link: ANYFIN_URL + "/de_DE",
        text: "Home"
      }
    ],
    menuLinksSecondary: [
      {
        link: ANYFIN_URL + "/de_DE/contact",
        text: "Contact",
        primary: false
      }
    ],
    compliance: [
      {
        link: information.DE.SECCI,
        text: "SEKKI"
      },
      {
        link: ANYFIN_URL + "/de_DE/privacy-policy",
        text: "Datenschutz"
      },
      {
        link: ANYFIN_URL + "/de_DE/cookies",
        text: "Cookies"
      }
    ],
    social: [
      {
        text: "Facebook",
        src: iconFacebook,
        link: information.SE.social.facebook
      },
      {
        text: "Instagram",
        src: iconInstagram,
        link: information.DE.social.instagram
      },
      {
        text: "YouTube",
        src: iconYoutube,
        link: information.SE.social.youtube
      }
    ]
  },
  NO: {
    menuLinksPrimary: [
      {
        link: ANYFIN_URL + "/nb_NO",
        text: "Hjem"
      }
    ],
    menuLinksSecondary: [
      {
        link: ANYFIN_URL + "/nb_NO/contact",
        text: "Kontakt",
        primary: false
      }
    ],
    compliance: [
      {
        link: information.NO.SECCI,
        text: "SEF-skjema"
      },
      {
        link: ANYFIN_URL + "/nb_NO/privacy-policy",
        text: "Personvernerklæring"
      },
      {
        link: ANYFIN_URL + "/nb_NO/cookies",
        text: "Cookies"
      }
    ],
    social: [
      {
        text: "Facebook",
        src: iconFacebook,
        link: information.NO.social.facebook
      },
      {
        text: "Instagram",
        src: iconInstagram,
        link: information.NO.social.instagram
      },
      {
        text: "YouTube",
        src: iconYoutube,
        link: information.NO.social.youtube
      }
    ]
  }
};

const getLinks = (country: COUNTRY_CODES, t: TFunction) => {
  const linksAnyfin = {
    caption: "Anyfin",
    items: footerLinks[country].menuLinksPrimary
  };
  const linksMore = {
    caption: t("more"),
    items: footerLinks[country].menuLinksSecondary
  };
  const linksLegal = {
    caption: t("legal"),
    items: footerLinks[country].compliance
  };
  const linksSocial = {
    caption: "Social",
    items: footerLinks[country].social
  };

  const links = [linksAnyfin, linksMore, linksLegal, linksSocial];
  return links;
};

const getIcons = (country: COUNTRY_CODES) => {
  return footerLinks[country].social.map(i => ({
    ...i,
    src: i.src
  }));
};

const SiteFooter = () => {
  const [country] = useCountry();
  const { t } = useTranslation("common");
  const c = country.toUpperCase() as COUNTRY_CODES;

  const companyInformationContent = information[c] ?? information.SE;

  const footerContent = {
    appDownload: {
      googleTextBottom: t("appDownload.google.title"),
      googleTextTop: t("appDownload.google.caption"),
      appleTextBottom: t("appDownload.ios.title"),
      appleTextTop: t("appDownload.ios.caption"),
      mobileText: t("appDownload.button"),
      onClick: () => downloadApp()
    },
    button: {
      text: "",
      top: t("appDownload.title")
    },
    country: {
      caption: "Land",
      countryPicker: function CountryPicker() {
        return <CountrySelect />;
      }
    },
    links: getLinks(c, t),
    company: {
      info: (
        <CompanyInformation
          // @ts-expect-error type should be union of all company-info
          companyInformationContent={companyInformationContent}
          t={t}
        />
      )
    },
    icons: getIcons(c)
  };
  // @ts-expect-error Footer types in anyfin/ui are too strict
  return <Footer content={footerContent} />;
};

const CompanyInformation = ({
  companyInformationContent,
  t
}: {
  t: TFunction;
  companyInformationContent: Partial<(typeof information)["SE"]>;
}) => {
  return (
    <span itemScope itemType="http://schema.org/Organization">
      <meta itemProp="url" content="https://anyfin.com/" />
      <meta itemProp="logo" content="https://anyfin.com/static/og-logo.png" />
      <span itemProp="name">{companyInformationContent.companyName}</span>
      ,&nbsp;
      <span
        itemProp="address"
        itemScope
        itemType="http://schema.org/PostalAddress"
      >
        <span itemProp="streetAddress">
          {`${companyInformationContent.adressStreet}, ${companyInformationContent.adressPostal} ${companyInformationContent.adressCity}`}
        </span>
        <span>{` Org. nummer: ${companyInformationContent.organisationNumber}`}</span>
      </span>
      <div>{t("disclaimer", { year: String(new Date().getFullYear()) })}</div>
    </span>
  );
};

export default withRouter(SiteFooter);
