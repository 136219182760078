import { Box, Typography } from "@anyfin/ui";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

interface RowProps {
  isHeader?: boolean;
  hideRowBorder?: boolean;
  isInvoice?: boolean;
}

const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: ${props => (props.isInvoice ? "0.5rem" : "1rem")};
  align-items: ${props => (props.isInvoice ? "center" : "center")};

  &:not(:last-of-type) {
    border-bottom: 1px solid
      ${props => (props.hideRowBorder ? "transparent" : "#CFC8C0")};
    padding-bottom: ${props =>
      props.isInvoice ? "0.5rem" : props.isHeader ? ".5rem" : "1rem"};
  }

  & > * {
    flex: 1 1 0;
  }
`;

const CompactRow = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;
  align-items: flex-end;

  & > * {
    flex: 0 1 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

interface StyledTypographyProps {
  lineThrough?: boolean;
  emphasize?: boolean;
  deemphasize?: boolean;
}

const StyledTypography = styled(Typography)<StyledTypographyProps>`
  color: ${props =>
    props.emphasize
      ? "#199F72"
      : props.deemphasize
      ? props.theme.colors.textLight.medium
      : "inherit"};
  text-decoration: ${props => (props.lineThrough ? "line-through" : "initial")};
`;

const CompactStyledTypography = styled(Typography)<StyledTypographyProps>`
  color: ${props =>
    props.emphasize
      ? "#199F72"
      : props.deemphasize
      ? props.theme.colors.textLight.medium
      : "inherit"};
  text-decoration: ${props => (props.lineThrough ? "line-through" : "initial")};
  padding-bottom: 0.1rem;
`;

interface TableCompareValue {
  value: string;
  lineThrough?: boolean;
  emphasize?: boolean;
  deemphasize?: boolean;
  smallRow?: boolean;
}

export interface TableCompareItem {
  title: string;
  values: Array<TableCompareValue>;
  isHeader?: boolean;
  titleAfter?: string;
}

interface TableCompareProps {
  items: Array<TableCompareItem>;
  headerSize?: number;
  contentSize?: number;
  hideRowBorder?: boolean;
  tableFooter?: { months: number; monthlyPayment: string };
  isMultiOfferMode?: boolean;
  isInvoice?: boolean;
  containerType?: "row" | "column";
}

const TableCompare = ({
  items,
  headerSize,
  contentSize,
  hideRowBorder,
  tableFooter,
  isMultiOfferMode = true,
  isInvoice = false,
  containerType = "column"
}: TableCompareProps) => {
  const { t } = useTranslation("offer");
  const Container = containerType === "row" ? Row : Column;
  return (
    <Box
      margin={0}
      padding={0}
      paddingRight={isMultiOfferMode ? 1.5 : 0}
      paddingBottom={0.5}
    >
      {items.map((item, i) => (
        <Row
          key={i}
          isHeader={item.isHeader}
          hideRowBorder={item.isHeader || hideRowBorder}
          isInvoice={isInvoice}
        >
          {item.values.map(
            ({ value, lineThrough, emphasize, deemphasize, smallRow }, j) => {
              if (item.isHeader)
                return (
                  <StyledTypography size={headerSize ?? 1} medium>
                    {value}
                  </StyledTypography>
                );

              return (
                <Container
                  key={j}
                  style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                >
                  <StyledTypography size={0.75} deemphasize>
                    {j === 1 && item.titleAfter ? item.titleAfter : item.title}
                  </StyledTypography>
                  {smallRow ? (
                    <CompactRow>
                      <StyledTypography
                        size={contentSize ?? 1.5}
                        emphasize={emphasize}
                        deemphasize={deemphasize}
                        lineThrough={lineThrough}
                      >
                        {value}
                      </StyledTypography>
                      <CompactStyledTypography
                        size={0.8}
                        emphasize={emphasize}
                        deemphasize={deemphasize}
                        lineThrough={lineThrough}
                      >
                        {t("offer_loan_detail.flexible_interest")}
                      </CompactStyledTypography>
                    </CompactRow>
                  ) : (
                    <StyledTypography
                      size={contentSize ?? 1.5}
                      emphasize={emphasize}
                      deemphasize={deemphasize}
                      lineThrough={lineThrough}
                    >
                      {value}
                    </StyledTypography>
                  )}
                </Container>
              );
            }
          )}
        </Row>
      ))}
      <Box paddingTop={1}>
        {tableFooter && (
          <StyledTypography size={0.75} deemphasize>
            <Trans
              t={t}
              i18nKey="offer_loan_detail.table_footer"
              defaults="offer_loan_detail.table_footer"
              values={{
                months: tableFooter.months,
                monthlyPayment: tableFooter.monthlyPayment
              }}
            />
          </StyledTypography>
        )}
      </Box>
    </Box>
  );
};

export default TableCompare;
