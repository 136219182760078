import React from "react";
import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Dropdown from "./Dropdown";
import { ReactComponent as FlagSwe } from "../assets/flag/swe.svg";
import { ReactComponent as FlagFin } from "../assets/flag/fin.svg";
import { ReactComponent as FlagDeu } from "../assets/flag/deu.svg";
import { ReactComponent as FlagNo } from "../assets/flag/no.svg";
import chevronDown from "../assets/chevron-down.svg";

const Trigger = styled.button<{ visible: boolean; minWidth: number }>`
  position: relative;
  font-family: inherit;
  font-size: inherit;
  display: block;
  border-radius: 0.5em;
  cursor: pointer;
  padding: 0.75em;
  color: ${props => props.theme.primary};
  background-color: ${props => (props.visible ? "#E7E9F5" : "#E7E9F5")};
  border: 1px solid white;
  outline: none;
  min-width: ${props => props.minWidth}px;

  :after {
    content: "";
    display: block;
    position: absolute;
    top: 25%;
    right: 0.5em;
    background: url(${chevronDown}) no-repeat top right;
    width: 20px;
    height: 20px;
  }
`;

const BaseList = styled.ul<{ minWidth: number }>`
  background-color: white;
  position: relative;
  border-radius: 0.5em;
  color: ${props => props.theme.primary};
  min-width: ${props => props.minWidth}px;
  overflow: hidden;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
  top: 5px;

  li {
    cursor: pointer;
    padding: 0.75em;
    &:hover {
      background-color: #e7e9f5;
    }
  }
`;

const ListItem = styled.li`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const List = Object.assign(BaseList, { Item: ListItem });

const flagStyle = css`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-right: 1em;
  object-fit: cover;
`;

const StyledFlagSwe = styled(FlagSwe)`
  ${flagStyle}
`;

const StyledFlagFin = styled(FlagFin)`
  ${flagStyle}
`;

const StyledFlagDeu = styled(FlagDeu)`
  ${flagStyle}
`;
const StyledFlagNo = styled(FlagNo)`
  ${flagStyle}
`;

const locales = [
  {
    locale: "sv_SE",
    flag: StyledFlagSwe,
    name: "Svenska",
    key: "sv_SE",
    link: "/sv_SE/login"
  },
  {
    locale: "fi_FI",
    flag: StyledFlagFin,
    name: "Suomi",
    key: "fi_FI",
    link: "/fi_FI/login"
  },
  {
    locale: "de_DE",
    flag: StyledFlagDeu,
    name: "Deutsch",
    key: "de_DE",
    link: "/de_DE/login"
  },
  {
    locale: "nb_NO",
    flag: StyledFlagNo,
    name: "Bokmål",
    key: "nb_NO",
    link: "/nb_NO/login"
  }
];
type LocaleItem = (typeof locales)[number];

const CountrySelect = ({ history }: RouteComponentProps) => {
  const { i18n } = useTranslation();

  return (
    <Dropdown
      data={locales}
      value={i18n.language.replace("-", "_")}
      minWidth={288}
      triggerComponent={Trigger}
      listComponent={List}
      renderItem={(country: LocaleItem) => (
        <List.Item
          key={country.locale}
          onClick={() => history.push(country.link)}
        >
          <country.flag preserveAspectRatio="xMidYMin slice" />
          {country.name}
        </List.Item>
      )}
    />
  );
};

export default withRouter(CountrySelect);
