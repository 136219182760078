import React from "react";
import { CustomerType, OfferType } from "../../../types/types";
import { OfferSignContext } from "../providers/OfferSignContext";
import { TFunction, useTranslation } from "react-i18next";
import OfferButtonCta from "./OfferButtonCta";
import * as Sentry from "@sentry/browser";
import { Helmet } from "react-helmet";
import * as ssntools from "@anyfin/ssntools";
import { COUNTRY_CODES, CURRENCY_CODES } from "../../../utils/countries";
import Section from "../../../components/Section";
import { Checkbox, Box } from "@anyfin/ui";
import { map, uniqBy } from "lodash";
import { AuthContext } from "../../../auth";
import Greeting from "./Greeting";
import OfferDetails from "./OfferDetails";
import InvoiceSummary from "./InvoiceSummary";
import OfferFeatures from "./OfferFeatures";
import OfferAdjustments from "./OfferAdjustments";
import OfferTerms from "./OfferTerms";
import { trackEvent } from "../../../utils/segment";
import { getTrackingProps } from "../OfferPage";
import { formatCurrency } from "@anyfin/number-formatter";
import { useCountry } from "@anyfin/number-formatter/components";
import { History } from "history";
import { useHistory, useRouteMatch } from "react-router-dom";
import Referral from "./Referral";
import needHelp from "../../../utils/needHelp";
import { OfferTotalsType } from "../types";
import { isPreview } from "../../../utils/routes";
import { trackOfferViewed } from "../trackOfferViewed";
import BankIDQRModal from "../../../components/BankIDQRModal";
import { ProviderAvatarBox } from "../components/OfferProviderCard";
import ProviderAvatar from "../../../components/ProviderAvatar";

interface OfferFormProps {
  policy: any;
  customer: CustomerType;
  offersToken: string;
  offers: Array<OfferType>;
  totals: OfferTotalsType;
  className: any;
  promoCodeAdded: boolean;
  showDiscountImpact: boolean;
  isReferred: boolean;
}

/**
 * Whenever this component mounts all data is done loading and user
 * is eligible to view the offer.
 */
const OfferForm = ({
  className,
  offers,
  offersToken,
  customer,
  totals,
  showDiscountImpact,
  policy,
  promoCodeAdded,
  isReferred
}: OfferFormProps) => {
  // Custom hooks
  const { t } = useTranslation("offer");
  const {
    status,
    startSign,
    error,
    signOfferData,
    cancel: cancelSigning
  } = React.useContext(OfferSignContext);
  const auth = React.useContext(AuthContext);
  const [country] = useCountry();
  const history = useHistory();
  const match = useRouteMatch();

  // State variables
  const [errorMessage, setErrorMessage] = React.useState("");
  const [user, setUser] = React.useState(null);
  const offerContentRef = React.useRef<HTMLDivElement>(null);
  const offerConsentRef = React.useRef<HTMLDivElement>(null);

  // Country specific logic
  // For some countries we need the customer to tick a checkbox before signing.
  const needsConsent = [COUNTRY_CODES.NO].includes(customer.countryCode);
  const [disabled, setDisabled] = React.useState(true);
  // For some countries the offer terms come into the signing flow.
  const isInfoCert = [COUNTRY_CODES.DE].includes(customer.countryCode);
  const isBankID = [COUNTRY_CODES.SE].includes(customer.countryCode);

  // Tracking
  const currency = formatCurrency(country, { display: "code" });
  const variation = customer.offersVariation || "saving";
  const trackingProps = React.useMemo(
    () => getTrackingProps(offers || [], variation, totals, currency),
    [offers, variation, totals, currency]
  );
  const offerIds = map(offers, "id");
  const isTailoredOffer =
    offers.length === 1 && offers[0].application.isTailored;

  const [showBankIDQRModal, setShowBankIDQRModal] = React.useState(false);

  // Track offer viewed
  React.useEffect(() => {
    if (!isPreview(match)) {
      trackOfferViewed(customer, totals, currency as CURRENCY_CODES);
    }
  }, []);

  // Track offer events
  React.useEffect(() => {
    if (!status) return;

    switch (status) {
      case "INIT": {
        trackEvent("Refi Offer Signing Started", trackingProps);
        break;
      }
      case "FAILED": {
        const errMessage = (error as any).graphQLErrors?.[0]?.message;
        const text = getBankIdSigningMessage(t, errMessage);
        setErrorMessage(text);
        Sentry.captureException(error, {
          tags: {
            component: "OfferForm"
          },
          extra: {
            errMessage
          }
        });
        trackEvent("Refi Offer Signing Failed", {
          ...trackingProps,
          errMessage
        });
        break;
      }
      case "COMPLETED": {
        trackEvent("Offer Signed", trackingProps);
        if (user) {
          const selectedOffers = customer.offers.filter(({ id }) =>
            offerIds.includes(id)
          );
          const shortIds = selectedOffers.map(offer => offer.shortId);
          if (isBankID) {
            setShowBankIDQRModal(false);
          }
          redirectToSuccess(history, shortIds);
        }
        break;
      }
    }
  }, [status, user]);

  // Functions
  const getFormattedPno = React.useCallback(() => {
    try {
      return ssntools.format(customer.personalIdentifier, customer.countryCode);
    } catch (e) {
      return "XXXX-XXX";
    }
  }, [customer]);

  const handleSubmit = React.useCallback(
    async e => {
      const isSigning =
        ["NEW", "FAILED", "COMPLETED"].includes(status) === false;
      const offerIds = map(offers, "id");

      e.preventDefault();
      if (!isSigning) {
        try {
          if (isBankID) {
            setShowBankIDQRModal(true);
          }
          const token = await startSign(offerIds, offersToken);
          const u = await auth.login(token);
          setUser(u);
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              countryCode: customer.countryCode,
              component: "OfferForm"
            }
          });
        }
      }
    },
    [status, offers, offersToken, auth]
  );

  const handleSubmitScroll = React.useCallback(
    async e => {
      e.preventDefault();

      offerConsentRef.current?.scrollIntoView({
        behavior: "smooth"
      });

      return Promise.resolve();
    },
    [offerConsentRef.current]
  );

  const getProviderAvatars = React.useCallback(() => {
    if (!offers.length) return <></>;

    const lenders = uniqBy(
      offers.map(offer => offer.lender),
      "id"
    );

    return (
      <ProviderAvatarBox>
        {lenders.map(lender => {
          return (
            <ProviderAvatar
              provider={lender}
              showBorder={true}
              key={lender.id}
              demoProvider={customer.isDemo ? t("demo_lender") : undefined}
            />
          );
        })}
      </ProviderAvatarBox>
    );
  }, [offers]);

  const triggerProps = {
    handleSubmit,
    needHelp,
    personalIdentifier: getFormattedPno(),
    disabled,
    error: errorMessage
  };

  return (
    <div className={className}>
      <Helmet title={t("page_title")} />
      <Greeting
        customer={customer}
        readMore={() =>
          offerContentRef.current?.scrollIntoView({ behavior: "smooth" })
        }
        isTailoredOffer={isTailoredOffer}
        getProviderAvatars={getProviderAvatars}
      >
        <OfferButtonCta
          triggerProps={{
            ...triggerProps,
            handleSubmit: isInfoCert ? handleSubmit : handleSubmitScroll
          }}
          styleOverrideProps={{ iconLeft: "", iconRight: "ArrowDown" }}
          showSEText={country === "SE"}
        />
      </Greeting>
      {/* Offer Content Ref for scrolling */}
      <Box ref={offerContentRef} marginBottom={"xxxlarge"}></Box>
      {promoCodeAdded && totals.discountAmount ? (
        <Box marginBottom={7.5}>
          <Referral discount={totals.discountAmount} isReferred={isReferred} />
        </Box>
      ) : null}
      <Box marginBottom={3.75}>
        <OfferDetails
          country={country}
          offers={offers}
          demoProvider={customer.isDemo ? t("demo_lender") : undefined}
        />
      </Box>
      <Box>
        <InvoiceSummary
          offers={offers}
          offersToken={offersToken}
          totals={totals}
          showDiscountImpact={showDiscountImpact}
        />
      </Box>
      <Box marginTop={7.5} marginBottom={7}>
        <OfferFeatures />
      </Box>
      <Box marginBottom={8}>
        <OfferAdjustments offers={offers} />
      </Box>
      <Box>
        {!isInfoCert ? (
          <>
            <OfferTerms
              countryCode={customer.countryCode}
              customer={customer}
              offers={offers}
              policy={policy}
            />
            {/* Consent and offer sign button */}
            <Section>
              <Box
                padding={0.5}
                paddingTop={14}
                paddingBottom={6}
                $color="alpha.100"
                ref={offerConsentRef}
              >
                {needsConsent ? (
                  <Box marginBottom={1}>
                    <Checkbox
                      checked={!disabled}
                      onChange={(e: any) =>
                        setDisabled(!e.currentTarget.checked)
                      }
                      label={t("step_3.confirm_loan_payoff")}
                    />
                  </Box>
                ) : null}
                <OfferButtonCta
                  disabled={needsConsent && disabled}
                  triggerProps={triggerProps}
                />
              </Box>
            </Section>
          </>
        ) : (
          <Section>
            <Box padding={0.5} paddingBottom={6}>
              <OfferButtonCta
                disabled={needsConsent && disabled}
                triggerProps={triggerProps}
              />
            </Box>
          </Section>
        )}
      </Box>
      {isBankID && (
        <BankIDQRModal
          showQRModal={showBankIDQRModal}
          toggleModal={async () => {
            cancelSigning();
            setShowBankIDQRModal(!showBankIDQRModal);
          }}
          autoStartToken={signOfferData.autoStartToken}
          qrContent={signOfferData.qrContent}
          modalTitle={t("step_3.accept_with_bank_id")}
        />
      )}
    </div>
  );
};

const redirectToSuccess = (history: History, shortIds: Array<string>) => {
  window.scrollTo(0, 0);
  // Redirect to success, inject signed shortIds into state
  history.replace(`success`, { shortIds });
};

const getBankIdSigningMessage = (t: TFunction, errStatus: string) => {
  const ERROR_MESSAGES: Record<string, string> = {
    userCancel: t("sign_error.user_cancel"),
    alreadyInProgress: t("sign_error.already_in_progress"),
    cancelled: t("sign_error.cancelled"),
    certificateErr: t("sign_error.certificate_error"),
    expiredTransaction: t("sign_error.expired_transaction")
  };

  return ERROR_MESSAGES[errStatus] ?? t("sign_error.default");
};

export default OfferForm;
