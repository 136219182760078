import { gql, useQuery } from "@apollo/client";
import { useContext } from "react";
import { AuthContext } from "../../../auth";
import { OfferType, PaymentType, ApplicationType } from "../../../types/types";

const OFFER_FRAGMENT = gql`
  fragment OfferFragment on Offer {
    id
    createdAt
    signedAt
    shortId
    applicationId
    application {
      isDdi
      isTailored
      referrer {
        id
      }
    }
    promoCode {
      type
      code
      amount
    }
    savedAmount
    hasDiscountCode
    amount
    months
    originalAmount
    pricingMonths
    amortizationRate
    monthlyPayment
    effectiveApr
    financingCost
    interestRate
    monthlyFee
    oldEffectiveApr
    oldFinancingCost
    oldInterestRate
    oldMonthlyFee
    originalMonths
    originalMonthlyPayment
    lender {
      id
      name
      displayName
      brandAttributes {
        backgroundColor
        foregroundColor
        acronym
      }
    }
    contract {
      id
      publicUrl
    }
    isBaseInterestRateUncertain
    nextPayments {
      date
      amount
    }
    interestRateDetails {
      floatingInterestRate
      marginInterestRate
    }
  }
`;

const CUSTOMER_FRAGMENT = gql`
  fragment CustomerOfferFragment on Customer {
    id
    personalIdentifier
    countryCode
    name
    email
    sms
    isDemo
    firstName
    address
    referrerId
    hasFlexOffersOrLoans
    hasActiveLoans
    features
    applications {
      affiliate
    }
    offers(status: "open") {
      ...OfferFragment
    }
    offersVariation
    creditor {
      id
      displayName
      type
    }
    nextPayments(includeTransfers: true) {
      date
      amount
    }
  }
  ${OFFER_FRAGMENT}
`;

const CUSTOMER_OFFER_SESSION_QUERY = gql`
  query CustomerQuery {
    customer: me {
      offersToken
      offers(status: "open") {
        ...OfferFragment
        externalStatement {
          loanType
          variation
        }
      }
      ...CustomerOfferFragment
    }
  }
  ${CUSTOMER_FRAGMENT}
  ${OFFER_FRAGMENT}
`;

const CUSTOMER_TOKEN_QUERY = gql`
  query CustomerQuery($offersToken: String!) {
    customer: customerByOffersToken(offersToken: $offersToken) {
      ...CustomerOfferFragment
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export interface CustomerOfferQueryType {
  customer: {
    id: number;
    personalIdentifier: string;
    email: string;
    name: string;
    countryCode: string;
    firstName: string;
    address: string;
    referrerId: string;
    hasFlexOffersOrLoans: string;
    hasActiveLoans: string;
    /** @deprecated */
    offersToken: string;
    applications: ApplicationType[];
    offers: OfferType[];
    creditor: {
      id: string;
      displayName: string;
      type: string;
    };
    nextPayments: PaymentType[];
    offersVariation: string;
  };
}

const useRefinanceOffers = (token?: string) => {
  const { loggedIn } = useContext(AuthContext);
  const OfferQuery =
    loggedIn && !token ? CUSTOMER_OFFER_SESSION_QUERY : CUSTOMER_TOKEN_QUERY;

  const { loading, data, refetch, error } = useQuery<CustomerOfferQueryType>(
    OfferQuery,
    {
      variables: { offersToken: token },
      context: { service: "gateway" }
    }
  );

  const { offers, offersToken, nextPayments } = data?.customer || {};
  if (error && !loggedIn && !token) {
    console.warn(
      "Not possible to fetch offers without token or valid session!"
    );
  }
  return {
    error,
    loading,
    data,
    offers,
    offersToken,
    nextPayments,
    refetch
  };
};

export default useRefinanceOffers;
