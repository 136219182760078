import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { media, Button } from "@anyfin/ui";

const StyledButton = styled(Button)`
  ${media.only.phone`
    width: 100%;
  `}
`;

const SignBankIDButton = ({
  handleSubmit,
  isSigning,
  error,
  fluid,
  showSEText,
  ...rest
}) => {
  const { t } = useTranslation("offer");
  return (
    <Fragment>
      <StyledButton
        onClick={handleSubmit}
        disabled={isSigning}
        loading={isSigning}
        iconRight={isSigning ? "Loading" : "ArrowRight"}
        iconLeft="BankidFilled"
        fluid={fluid}
        rounded
        elevated
        fontWeightMedium
        rightAlignedTitle
        style={{ height: "4rem" }}
        {...rest}
      >
        {showSEText
          ? t("step_3.tailored_offer_header_cta")
          : t("step_3.accept_with_bank_id")}
      </StyledButton>
    </Fragment>
  );
};

export default SignBankIDButton;
