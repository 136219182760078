import React from "react";
import PropTypes from "prop-types";
import { Modal as ModalBase, media, Icon, Typography } from "@anyfin/ui";
import styled from "styled-components";
import CopyContainer from "./CopyContainer";

const StyledModal = styled(ModalBase)`
  position: relative;
  border-radius: ${props => (props.fullScreen ? 0 : "32px")};
  padding: 2.5rem 0 2rem;
  max-width: 700px;
  max-height: 95vh;
  overflow: auto;

  ${media.down.phone`
    max-height: calc(100vh - 1rem);
    height: calc(100vh - 1rem);
    margin auto auto 0;
    border-radius: 32px 32px 0 0;
  `}
`;

const TitleTop = styled(Typography)`
  top: 1rem;
  position: absolute;
  text-align: center;
  width: 100%;
`;

const ModalCloseButton = styled.button`
  top: 1rem;
  right: 1rem;
  position: absolute;
  background: transparent;
  font-size: 2em;
  cursor: pointer;
  outline: 0;
  border: 0;

  ${media.down.tablet`
     right: 0;
     padding: 0 20px;
  `};
`;

export const ModalClose = ({ onClick, ...rest }) => (
  <ModalCloseButton onClick={onClick} {...rest}>
    <Icon icon="X" />
  </ModalCloseButton>
);

const Modal = ({
  open,
  title,
  toggleModal,
  padded = true,
  children,
  titleTop = "",
  ...rest
}) => {
  return (
    <StyledModal
      open={open}
      onEscapeKeydown={toggleModal}
      onBackgroundClick={toggleModal}
      transitionConfig={{
        direction: "up"
      }}
      {...rest}
    >
      <>
        {titleTop ? <TitleTop>{titleTop}</TitleTop> : null}
        <ModalClose onClick={toggleModal} padded={padded} />
        {title ? (
          <CopyContainer
            paddingTop="xlarge"
            paddingBottom="medium"
            paddingLeft="small"
          >
            <Typography.H5>{title}</Typography.H5>
          </CopyContainer>
        ) : null}
        {children}
      </>
    </StyledModal>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default Modal;
