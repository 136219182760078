import { Typography, Box, Button, theme } from "@anyfin/ui";
import CopyContainer from "../../../../components/CopyContainer";
import { OfferType } from "../../../../types/types";
import Modal from "../../../../components/Modal";
import { Trans, useTranslation } from "react-i18next";
import TableCompare, { TableCompareItem } from "./TableCompare";
import {
  CountryCode,
  formatMoney,
  formatPercent
} from "@anyfin/number-formatter";
import { useCountry } from "@anyfin/number-formatter/components";
import { TFunction } from "i18next";
import needHelp from "../../../../utils/needHelp";
import styled from "styled-components";
import OfferDetailsItem from "./OfferDetailsItem";
import ProviderCard from "../../../../components/ProviderCard";
import React, { useMemo } from "react";
import { COUNTRY_CODES } from "../../../../utils/countries";
import InvoiceOfferDetailsItem from "./InvoiceOfferDetailsItem";

interface CompareDetailsModalProps {
  offers: Array<OfferType>;
  isOpen: boolean;
  toggleModal: () => void;
  demoProvider?: string;
  assumedRate?: boolean;
}

const StyledButton = styled(Button)``;

export const OfferDetailsModal = ({
  offers,
  isOpen,
  toggleModal,
  demoProvider
}: CompareDetailsModalProps) => {
  const { t } = useTranslation("offer");
  const [country] = useCountry();

  const offersWithoutInvoices = useMemo(
    () =>
      offers.filter(
        entry => !entry?.externalStatement?.variation?.includes("INVOICE")
      ),
    [offers]
  );

  const invoiceOffers = useMemo(
    () =>
      offers.filter(entry =>
        entry?.externalStatement?.variation?.includes("INVOICE")
      ),
    [offers]
  );

  if (offers.length > 1) {
    return (
      <Modal
        open={isOpen}
        title=""
        toggleModal={toggleModal}
        titleTop={t("offer_loan_detail.details")}
      >
        <Box paddingLeft={0.5} paddingRight={0.5} paddingTop={2}>
          <Box marginLeft={1} marginBottom={0.5}>
            <Typography.H7>{t`offer_loan_detail.credit_title`}</Typography.H7>
          </Box>
          {offersWithoutInvoices.length > 0 ? (
            <>
              {offersWithoutInvoices.map(offer => (
                <OfferDetailsItem
                  key={offer.id}
                  offer={offer}
                  t={t}
                  country={country}
                />
              ))}
            </>
          ) : null}
          <Box paddingLeft={1} paddingRight={1} marginBottom={1}>
            <OfferRateAssumptionLink offers={offers} />
          </Box>

          {invoiceOffers.length > 0 ? (
            <>
              <Box marginLeft={1} marginTop={1} marginBottom={0.5}>
                <Typography.H7>{t`offer_loan_detail.invoices_title`}</Typography.H7>
              </Box>
              {invoiceOffers.map(offer => (
                <InvoiceOfferDetailsItem
                  key={offer.id}
                  offer={offer}
                  t={t}
                  country={country}
                />
              ))}
            </>
          ) : null}

          <Box padding={0} marginTop={3}>
            <StyledButton
              onClick={needHelp}
              iconRight="Chat"
              rightAlignedTitle
              color="white"
              fontWeightMedium
              fontSize={1}
            >
              {t("offer_loan_detail.something_wrong")}
            </StyledButton>
          </Box>
        </Box>
      </Modal>
    );
  }

  const offer = offers[0];
  const isInvoiceOffer = offer.externalStatement.variation?.includes("INVOICE");
  const isTailoredOffer = !!offer.application.isTailored;
  return (
    <Modal
      open={isOpen}
      title=""
      toggleModal={toggleModal}
      titleTop={demoProvider ?? offer.lender.name}
    >
      <Box paddingLeft={1.5} paddingRight={1.5} paddingTop={2}>
        <CopyContainer padding={0}>
          <Typography size={1.5} medium>
            {isInvoiceOffer || isTailoredOffer
              ? t("offer_loan_detail.invoice_title")
              : t("offer_loan_detail.title")}
          </Typography>
        </CopyContainer>
        <CopyContainer padding={0} marginTop={1}>
          <Typography size={1} style={{ whiteSpace: "break-spaces" }}>
            {t("offer_loan_detail.description")}
          </Typography>
        </CopyContainer>
        <Box marginTop={3}>
          {isInvoiceOffer || isTailoredOffer ? (
            <TableCompare
              items={getInvoiceOfferItems(offer, country, t, isTailoredOffer)}
              isMultiOfferMode={false}
              tableFooter={{
                months: offer.pricingMonths,
                monthlyPayment: formatMoney(offer.monthlyPayment, country, {
                  decimals: false
                })
              }}
              isInvoice={true}
              containerType="row"
            ></TableCompare>
          ) : (
            <TableCompare
              items={getOfferItems(offer, country, t)}
              isMultiOfferMode={false}
              tableFooter={{
                months: offer.pricingMonths,
                monthlyPayment: formatMoney(offer.monthlyPayment, country, {
                  decimals: false
                })
              }}
            ></TableCompare>
          )}
        </Box>
        <Box padding={0} marginTop={4.5}>
          <StyledButton
            onClick={needHelp}
            iconRight="Chat"
            rightAlignedTitle
            color="white"
            fontWeightMedium
            fontSize={1}
          >
            {t("offer_loan_detail.something_wrong")}
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export const getOfferItems = (
  offer: OfferType,
  country: CountryCode,
  t: TFunction,
  multiOffers = false
): Array<TableCompareItem> => {
  return [
    {
      title: "",
      values: [
        { value: t("offer_loan_detail.before_multi") },
        { value: t("offer_loan_detail.after_multi") }
      ],
      isHeader: true
    },
    {
      title: t("amount"),
      values: [
        {
          value: formatMoney(offer.amount, country, {
            decimals: false
          })
        },
        {
          value: formatMoney(offer.amount, country, {
            decimals: false
          })
        }
      ]
    },
    {
      title: t("offer_loan_detail.interest"),
      values: [
        {
          value: formatPercent(offer.oldInterestRate, country),
          deemphasize: true
        },
        {
          value: formatPercent(offer.interestRate, country),
          emphasize: true,
          smallRow: true
        }
      ]
    },
    {
      title: t("offer_loan_detail.effective_apr"),
      values: [
        {
          value: offer.oldEffectiveApr
            ? formatPercent(offer.oldEffectiveApr, country)
            : "-",
          deemphasize: true
        },
        { value: formatPercent(offer.effectiveApr, country), emphasize: true }
      ]
    },
    {
      title: t("offer_loan_detail.monthly_fee"),
      values: [
        {
          value: formatMoney(offer.oldMonthlyFee, country),
          deemphasize: true
        },
        { value: formatMoney(offer.monthlyFee, country), emphasize: true }
      ]
    },
    {
      title: t("offer_loan_detail.total_cost"),
      values: [
        {
          value: formatMoney(offer.amount + offer.oldFinancingCost, country, {
            decimals: false
          }),
          deemphasize: true
        },
        {
          value: formatMoney(offer.amount + offer.financingCost, country, {
            decimals: false
          }),
          emphasize: true
        }
      ]
    }
  ];
};

export const getInvoiceOfferItems = (
  offer: OfferType,
  country: CountryCode,
  t: TFunction,
  isTailoredOffer = false
): Array<TableCompareItem> => {
  return [
    {
      title: t("amount"),
      values: [
        {
          value: formatMoney(offer.amount, country, {
            decimals: false
          })
        }
      ]
    },
    {
      title: t("offer_loan_detail.interest"),
      values: [
        {
          value: formatPercent(offer.interestRate, country),
          emphasize: true,
          smallRow: true
        }
      ]
    },
    {
      title: t("offer_loan_detail.effective_apr"),
      values: [
        { value: formatPercent(offer.effectiveApr, country), emphasize: true }
      ]
    },
    {
      title: t("offer_loan_detail.monthly_fee"),
      values: [
        { value: formatMoney(offer.monthlyFee, country), emphasize: true }
      ]
    },
    {
      title: t("offer_loan_detail.total_cost"),
      values: [
        {
          value: formatMoney(offer.amount + offer.financingCost, country, {
            decimals: false
          }),
          emphasize: true
        }
      ]
    },
    ...(isTailoredOffer
      ? [
          {
            title: t("offer_loan_detail.term"),
            values: [
              {
                value: `${offer.pricingMonths} månader`,
                emphasize: true
              }
            ]
          }
        ]
      : [])
  ];
};

interface OfferRateAssumptionLinkProps {
  offers: Array<OfferType>;
}
export const OfferRateAssumptionLink = ({
  offers
}: OfferRateAssumptionLinkProps) => {
  const { t } = useTranslation("offer");
  const [country] = useCountry();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const uncertainOffers = offers.filter(
    offer => offer.isBaseInterestRateUncertain
  );

  if (!uncertainOffers.length || country === COUNTRY_CODES.NO) return null;

  return (
    <>
      <Typography size={0.875} color={theme.colors.textLight.medium}>
        <Trans
          t={t}
          i18nKey="offer_assumption_rate.notice"
          components={[
            <span
              key={0}
              onClick={() => setIsModalOpen(true)}
              style={{
                display: "inline-block",
                textDecoration: "underline",
                cursor: "pointer"
              }}
            >
              <Typography
                size={0.875}
                color={theme.colors.textLight.medium}
                medium
              />
            </span>
          ]}
        ></Trans>
      </Typography>
      <OfferRateAssumptionModal
        isOpen={isModalOpen}
        toggleModal={() => setIsModalOpen(!isModalOpen)}
        offers={uncertainOffers}
      ></OfferRateAssumptionModal>
    </>
  );
};

interface OfferRateAssumptionModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  offers: Array<OfferType>;
}
export const OfferRateAssumptionModal = ({
  isOpen,
  toggleModal,
  offers
}: OfferRateAssumptionModalProps) => {
  const { t } = useTranslation("offer");
  const [country] = useCountry();

  return (
    <Modal open={isOpen} toggleModal={toggleModal} title="">
      <CopyContainer paddingLeft={1.5} paddingRight={1.5} paddingTop={2}>
        <Typography size={2} style={{ marginBottom: "1rem" }} $as="h2">
          {t("offer_adjustment_details.rate_asumption_title")}
        </Typography>
        <Typography size={1} style={{ whiteSpace: "break-spaces" }}>
          {t("offer_adjustment_details.rate_asumption_description")}
        </Typography>
      </CopyContainer>
      <Box marginTop={2}>
        {offers.map(offer => (
          <ProviderCard
            key={offer.id}
            color="transparent"
            provider={offer.lender}
            description={undefined}
            onClick={undefined}
            labelRight={formatPercent(offer.interestRate, country)}
            iconRight={undefined}
            showAvatarBorder={undefined}
            isDemo={undefined}
          ></ProviderCard>
        ))}
      </Box>
      <CopyContainer marginTop={1} onClick={needHelp}>
        <Typography
          $as="span"
          color={theme.colors.textLight.medium}
          style={{ textDecoration: "underline" }}
          size={0.875}
        >
          {t("offer_adjustment_details.something_wrong")}
        </Typography>
      </CopyContainer>
    </Modal>
  );
};
