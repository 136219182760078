import * as React from "react";
import { OfferType } from "../../../types/types";
import styled from "styled-components";
import { Box, Typography, theme } from "@anyfin/ui";
import ProviderAvatar from "../../../components/ProviderAvatar";
import { formatMoney, formatPercent } from "@anyfin/number-formatter";
import { useCountry } from "@anyfin/number-formatter/components";
import PillButton from "../../../components/PillButton";
import { useTranslation } from "react-i18next";
import { uniqBy } from "lodash";

const StyledPillButton = styled(PillButton)`
  height: 32px;
  padding: 0 6px 0 12px;
`;

export const ProviderAvatarBox = styled(Box)`
  display: flex;
  flex-direction: row;

  & > *:not(:first-of-type) {
    margin-left: -1rem;
  }
`;

const OfferProviderBox = styled(Box)`
  padding: 1rem;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.alpha.hues[100]};
`;

const OfferProviderTopBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  flex-direction: initial;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const OfferDetailsBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-flow: row wrap;
  margin-left: 1rem;

  > * {
    flex: 50%;

    &:nth-child(2n) {
      text-align: right;
    }
  }
`;

const OfferProviderBottomBox = styled(Box)<{ setToStart?: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: ${props => (props.setToStart ? "start" : "space-between")};
  flex-direction: row;
`;

interface OfferProviderCardProps {
  offers: Array<OfferType>;
  onClick?: () => void;
  demoProvider?: string;
}
const OfferProviderCard = ({
  offers,
  onClick,
  demoProvider
}: OfferProviderCardProps) => {
  const [country] = useCountry();
  const { t } = useTranslation("offer");
  if (!offers.length) return null;

  const multiOffers = offers.length > 1;

  const getProviderAvatars = React.useCallback(() => {
    if (!offers.length) return <></>;

    const lenders = uniqBy(
      offers.map(offer => offer.lender),
      "id"
    );

    return (
      <ProviderAvatarBox>
        {lenders.map(lender => {
          return (
            <ProviderAvatar
              provider={lender}
              showBorder={true}
              key={lender.id}
              demoProvider={demoProvider}
            />
          );
        })}
      </ProviderAvatarBox>
    );
  }, [offers]);

  if (multiOffers) {
    const offersTotal = offers.reduce((sum, o) => sum + o.amount, 0);

    return (
      <OfferProviderBox>
        <OfferProviderTopBox>
          <div>
            {getProviderAvatars()}
            <Typography size={1.125} medium style={{ marginLeft: "1rem" }}>
              {t("offer_provider_card.total_credits", {
                credits: offers.length
              })}
            </Typography>
          </div>
        </OfferProviderTopBox>

        <OfferProviderBottomBox>
          <Box>
            <Typography $as="span">{t("offer_provider_card.total")}</Typography>
            <Typography size={2}>
              {formatMoney(offersTotal, country)}
            </Typography>
          </Box>
          <StyledPillButton
            color="alpha.200"
            iconRight="ArrowRight"
            onClick={onClick}
            fontWeightMedium
          >
            {t("offer_provider_card.compare_terms_individual")}
          </StyledPillButton>
        </OfferProviderBottomBox>
      </OfferProviderBox>
    );
  }

  const offer = offers[0];
  const isInvoiceOffer = offer.externalStatement.variation?.includes("INVOICE");
  const isTailoredOffer = !!offer.application.isTailored;

  const showBalanceSplit =
    offer.originalAmount &&
    offer.originalAmount !== offer.amount &&
    offer.originalAmount > offer.amount;

  if (isTailoredOffer) {
    return (
      <OfferProviderBox>
        <OfferProviderTopBox>
          <OfferDetailsBox style={{ gap: "24px", marginLeft: "0rem" }}>
            <div style={{ flex: "1 1" }}>
              <Typography size={1.125} color={theme.colors.black}>
                {t("amount")}
              </Typography>
              <Typography size={2} color={theme.colors.black}>
                {formatMoney(offer.amount, country, { decimals: false })}
              </Typography>
            </div>
            <div style={{ flex: "1 1", textAlign: "left" }}>
              <Typography size={1.125} color={theme.colors.black}>
                {t("offer_loan_detail.interest")}
              </Typography>
              <Typography size={2} color={theme.colors.green.letters?.B}>
                {formatPercent(offer.interestRate, country, { decimals: true })}
              </Typography>
            </div>
          </OfferDetailsBox>
        </OfferProviderTopBox>
        <OfferProviderBottomBox setToStart>
          <Box></Box>
          <StyledPillButton
            color="alpha.200"
            iconRight="ArrowRight"
            onClick={onClick}
            fontWeightMedium
          >
            {t("offer_provider_card.compare_terms_individual")}
          </StyledPillButton>
        </OfferProviderBottomBox>
      </OfferProviderBox>
    );
  } else {
    return (
      <OfferProviderBox>
        <OfferProviderTopBox>
          {getProviderAvatars()}
          <OfferDetailsBox>
            <div>
              <Typography size={1.125} medium>
                {demoProvider ?? offer.lender.name}
              </Typography>
              {showBalanceSplit ? (
                <Typography size={0.875} color={theme.colors.textLight.medium}>
                  {t("offer_provider_card.amount_out_of", {
                    amount: offer.amount,
                    originalAmount: offer.originalAmount
                  })}
                </Typography>
              ) : null}
            </div>
            <div>
              <Typography
                size={1.125}
                medium
                color={theme.colors.green.letters?.B}
              >
                {formatPercent(offer.interestRate, country, { decimals: true })}
              </Typography>
              {!isInvoiceOffer && (
                <Typography
                  size={0.875}
                  style={{ textDecoration: "line-through" }}
                >
                  {formatPercent(offer.oldInterestRate, country, {
                    decimals: true
                  })}
                </Typography>
              )}
            </div>
          </OfferDetailsBox>
        </OfferProviderTopBox>
        <OfferProviderBottomBox>
          <Box></Box>
          <StyledPillButton
            color="alpha.200"
            iconRight="ArrowRight"
            onClick={onClick}
            fontWeightMedium
          >
            {t("offer_provider_card.compare_terms_individual")}
          </StyledPillButton>
        </OfferProviderBottomBox>
      </OfferProviderBox>
    );
  }
};

export default OfferProviderCard;
