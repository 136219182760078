import React, { useContext } from "react";

import SignBankIDButton from "./SignBankIDButton";
import SignScriveButton from "./SignScriveButton";
import SignInfoCertButton from "./SignInfoCertButton";
import SignInAppButton from "./SignInAppButton";
import { OfferSignContext } from "../../providers/OfferSignContext";

export interface TriggerProps {
  handleSubmit: (e: any) => Promise<void>;
  needHelp: (e: any) => void;
  personalIdentifier: string;
  disabled: boolean;
  error: any;
}

interface StyleOverrideProps {
  iconLeft: string;
  iconRight: string;
}

interface OfferButtonCtaProps {
  triggerProps: TriggerProps;
  fluid?: boolean;
  disabled?: boolean;
  styleOverrideProps?: StyleOverrideProps;
  showSEText?: boolean;
}

const OfferButtonCta = ({
  triggerProps,
  fluid,
  disabled,
  styleOverrideProps,
  showSEText = false
}: OfferButtonCtaProps) => {
  const {
    provider,
    startSign,
    status,
    cancel: cancelSign,
    signOfferData
  } = useContext(OfferSignContext);

  const isSigning = ["NEW", "FAILED", "COMPLETED"].includes(status) === false;

  const buttonProps = {
    ...triggerProps,
    isSigning,
    cancelSign,
    signOfferData,
    fluid,
    disabled,
    iconSize: 1.5,
    ...styleOverrideProps,
    showSEText
  };

  return (
    <>
      {provider === "BankID" ? (
        <SignBankIDButton {...buttonProps} />
      ) : provider === "Scrive" ? (
        <SignScriveButton {...buttonProps} />
      ) : provider === "InfoCert" ? (
        <SignInfoCertButton
          fluid={fluid}
          disabled={disabled}
          handleSubmit={() => startSign([], "")}
          {...styleOverrideProps}
        />
      ) : provider === "Unsupported" ? (
        <SignInAppButton fluid={fluid} disabled={disabled} />
      ) : null}
    </>
  );
};

export default OfferButtonCta;
